import Swal from "sweetalert2";

export const parseErrorResponse = function (error) {
    let message;
    if (error.response !== null && error.response !== undefined) {
        if (error.response.data.detail) {
            message = error.response.data.detail;
        }
        if (error.response.data.message) {
            message = error.response.data.message;
        } else {
            let response = error.response.data;
            if (typeof response === "string") {
                try {
                    message = getStringFromObject(JSON.parse(response));
                } catch (e) {
                    message = "An error occurred. Please try again or contact support";
                }
            } else if (typeof response === 'object') {
                message = getStringFromObject(response);
            } else {
                message = response;
            }
        }


        if (message.length === 0) {
            switch (error.response.status) {
                case 401:
                    message = "Unauthorised. Please log in";
                    break;
                case 404:
                    message = "The requested resource could not be found";
                    break;
                default:
                    message = "An error occurred. Please try again, or contact support";
                    break;
            }
        }
    } else {
        if (typeof error === "string") {
            message = error;
        } else {
            message = "An error occurred. Please try again, or contact support";
        }

    }

    return message;
};

const getStringFromObject = function (obj) {
    let message = "";
    const map = new Map(Object.entries(obj));
    for (const [key, value] of map.entries()) {
        message = `${message} ${key}: ${value}`;
    }
    return message;
};

export const showAlert = (type, title, body, callback) => {
    Swal.fire({
        title: title,
        timer: 5000,
        icon: type,
        text: body,
        timerProgressBar: true,
    }).then(() => {
        if (callback)
            callback();
    });
}

export const handleChange = (e, self) => {
    const name = e.target.name;
    const value = e.target.value;
    self.setState({[name]: value});
}

export const handleChangeData = (e, self, number) => {
    const name = e.target.name;
    let value = e.target.value;
    if (number) {
        value = e.target.value ? parseInt(e.target.value.replace(/,/g, "")) : 0;
        if (isNaN(value))
            return;
    }

    let data = {...self.state.data};
    data[name] = value;
    self.setState({data: data});
}

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


export const throttle = // Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
    function throttle(func, wait, options) {
        let context, args, result;
        let timeout = null;
        let previous = 0;
        if (!options) options = {};
        let later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        };
        return function () {
            let now = Date.now();
            if (!previous && options.leading === false) previous = now;
            let remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    };

export const getUrl = (link) => {
    if (link.startsWith("http")) {
        link = link.substring(link.lastIndexOf("api") + 4);
    }
    return link;
}