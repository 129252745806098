import Dashboard from "../pages/dashboard";
import Login from "../pages/auth/login";
import Logout from "../pages/auth/logout/logout";
import BooksList from "../pages/books";
import OrdersList from "../pages/orders";
import OrderDetails from "../pages/orders/details/details";
import BookDetails from "../pages/books/details";
import Transactions from "../pages/transactions";
import BookTags from "../pages/book_tags";
import BookTagDetails from "../pages/book_tags/details";
import Settings from "../pages/settings";
import Blogs from "../pages/blogs";
import BlogDetails from "../pages/blogs/details";
import BlogAdd from "../pages/blogs/add";

const urls = {
    index: {
        path: '/',
        exact: true,
        component: Dashboard,
    },

    logout: {
        path: '/logout',
        exact: true,
        component: Logout,
    },

    login: {
        path: "/login",
        exact: true,
        component: Login
    },

    settings: {
        path: "/settings",
        exact: false,
        component: Settings
    },

    books: {
        path: "/books",
        exact: true,
        component: BooksList
    },

    book_details: {
        path: "/books/:id",
        exact: true,
        component: BookDetails
    },

    orders: {
        path: "/orders",
        exact: true,
        component: OrdersList
    },

    order_details: {
        path: "/orders/:id",
        exact: true,
        component: OrderDetails
    },

    blogs: {
        path: "/blogs",
        exact: true,
        component: Blogs
    },

    blog_add: {
        path: "/blogs/add",
        exact: true,
        component: BlogAdd
    },

    blog_details: {
        path: "/blogs/:id",
        exact: true,
        component: BlogDetails
    },

    blog_edit: {
        path: "/blogs/add/:id",
        exact: true,
        component: BlogAdd
    },

    transactions: {
        path: "/transactions",
        exact: true,
        component: Transactions
    },

    book_tags: {
        path: "/book-tags",
        exact: true,
        component: BookTags
    },

    book_tags_details: {
        path: "/book-tags/:id",
        exact: true,
        component: BookTagDetails
    },

}

export default urls;