import React from "react";
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";

class Totals extends React.Component {
    state = {
        loading: false,
        statistics: []
    }

    componentDidMount() {
        this.getStatistics();
    }

    getStatistics = () => {
        this.setState({
            loading: true
        })
        makeRequest(GET_REQUEST, endpoints.statistics, {}, response => {
            this.setState({
                statistics: response.data
            })
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {loading, statistics} = this.state;
        return (
            loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div className="col-12">
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-purple">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center ">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-purple">
                                            <span style={{fontSize: "2rem"}} className="icon-arrow-down-circle"/>
                                        </div>
                                        <div className="media-body text-light">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.pending_orders ?? "-"}</h4>
                                            <span>Pending Orders</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-primary">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white  sr-icon-box text-primary">
                                            <span style={{fontSize: "1.5rem"}} className="icon-folder"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.completed_orders ?? "-"}</h4>
                                            <span>Complete Orders</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-danger">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-danger">
                                            <span style={{fontSize: "1.5rem"}} className="icon-grid"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.total_books}</h4>
                                            <span>Total Books</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="card mb-4 bg-success">
                                <div className="card-body">
                                    <div className="media d-flex align-items-center">
                                        <div className="mr-4 rounded-circle bg-white sr-icon-box text-success">
                                            <i className="icon-people"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h4 className="text-uppercase mb-0 weight500">{statistics?.users}</h4>
                                            <span>Users</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Totals;