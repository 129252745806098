import {FETCH_ORDERS, LOADING_ORDERS} from "./actions";

const ordersState = {
    orders: null,
};

const reducer = (state = ordersState, action) => {
    if (action.type === FETCH_ORDERS) {
        return {...state, ...action, loading: false};
    }
    if (action.type === LOADING_ORDERS) {
        return {...state, loading: true};
    }
    return state;
};

export default reducer;

