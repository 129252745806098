import React, {Component} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {handleChangeData, showAlert} from "../../helpers/helper_functions";
import Swal from "sweetalert2";


const $ = window.$;

class BlogAdd extends Component {
    state = {
        data: {},
        name: '',
        loading: false,
        errors: {},
    }

    componentDidMount() {
        this.getBlogDetails();

        $("#body").summernote({
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview', 'help']]
            ],
            height: 700,
            callbacks: {
                onChange: body => {
                    this.setState({data: {...this.state.data, body: body}});
                }
            }
        });
    }

    getBlogDetails = () => {
        const {id} = this.props.match.params;
        const {history} = this.props;
        if (id) {
            makeRequest(GET_REQUEST, `${endpoints.all_blogs}${id}`, {}, response => {
                let data = {...response.data};
                this.setState({
                        data: data
                    }, () => {
                        window.$("#body").summernote('code', data.body);
                    }
                )
            }, () => {
                showAlert('error', 'Error', 'Could not load blog', () => history.goBack());
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {data} = this.state;
        let formData = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            formData.append(key, value);
        }

        const {id} = this.props.match.params;
        const {history} = this.props;
        const method = POST_REQUEST;
        if (id)
            formData.append('_method', 'PATCH');
        const route = id ? `${endpoints.all_blogs_admin}${data.id}` : endpoints.all_blogs_admin;
        makeRequest(method, route, formData, () => {
            Swal.fire(
                'Success!',
                `Blog was ${id ? "edited" : "saved"} successfully!`,
                'success'
            ).then(() => {
                history.goBack();
                /* $("#addBlogModal").modal('toggle');
                 $('body').removeClass('modal-open');
                 $('.modal-backdrop').remove();*/
            });
        }, (error) => {
            if (error.response.status === 413) {
                showAlert('error', 'Error', 'The uploaded file is too large. Please select another file')
            } else
                this.setState({
                    errors: error.response.data
                })
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {errors, loading, data} = this.state;
        const {history} = this.props;
        return (
            <div className="container">
                <div className="row mx-auto">
                    <div className="col-12 card px-5 py-5">
                        <form>
                            <div className="form-group">
                                <label>Title</label>
                                <input value={data.title || ""} onChange={(e) => handleChangeData(e, this)}
                                       type="text"
                                       className="form-control" name="title"/>
                                {errors.title && <p className="small text-danger">{errors.title[0]}</p>}
                            </div>
                            <div className="form-group">
                                <label>Cover Image:</label>
                                <input
                                    onChange={event => {
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                cover_image: event.target.files[0]
                                            }
                                        })
                                    }}
                                    type="file"
                                    accept="image/*"
                                    className="form-control"
                                    required/>
                                {errors.cover_image && (
                                    <p className="mb-0 small text-danger">{errors.cover_image[0]}</p>
                                )}
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <input
                                    value={data.description || ""}
                                    onChange={(e) => handleChangeData(e, this)} type="text"
                                    className="form-control" name="description"/>
                                {errors.description &&
                                <p className="small text-danger">{errors.description[0]}</p>}
                            </div>
                            <div className="form-group">
                                <label>Body</label>
                                <textarea id="body" required/>
                                {errors.body && (
                                    <p className="mb-0 small text-danger">{errors.body[0]}</p>
                                )}
                            </div>
                        </form>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => history.goBack()}>Cancel
                            </button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogAdd;