const endpoints = {
    statistics: "admin/statistics/",
    expenses_stats: "admin/expenses-statistics",
    login: "login/",
    book_types: "admin/book-types/",
    all_books: "admin/books/",
    all_blogs_admin: "admin/blogs/",
    all_blogs: "blogs/",
    document_books: "document-books/",
    audio_books: "audio-books/",
    video_books: "video-books/",
    orders: "admin/orders/",
    current_order: "users/orders/current/",
    me: "me/",
    logout: "logout/",
    transactions: "admin/transaction-history",
    csrf_cookie: "sanctum/csrf-cookie/",
    book_tags: "admin/book-tags/",
    password_reset: "forgot",
    complete_order: "admin/orders/complete/"
};

export default endpoints;