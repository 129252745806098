import React, {Component} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {PATCH_REQUEST, POST_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {handleChangeData, parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import Swal from "sweetalert2";

const $ = window.$;

class BookTagAdd extends Component {
    state = {
        data: {},
        name: '',
        loading: false,
        errors: {},
    }

    componentDidMount() {
        this.getBookDetails();
    }

    getBookDetails = () => {
        const book_tag = this.props?.book_tag;
        if (book_tag) {
            this.setState({
                data: book_tag
            })
        }

    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let {data} = this.state;

        const book_tag = this.props?.book_tag;
        const method = book_tag ? PATCH_REQUEST : POST_REQUEST;
        if (book_tag)
            data['_method'] = 'PATCH';
        const route = book_tag ? `${endpoints.book_tags}${book_tag?.id}` : endpoints.book_tags;
        makeRequest(method, route, data, () => {
            Swal.fire(
                'Success!',
                'Book Tag was saved successfully!',
                'success'
            ).then(() => {
                this.props.getBookTags();
                $("#addBookModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            if (error.response.status === 400)
                this.setState({
                    errors: error.response.data
                })
            else
                showAlert('error', 'Error', parseErrorResponse(error))
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {errors, loading, data} = this.state;
        return (
            <div className="modal fade" id="addBookModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Book Tag</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input value={data.name || ""} onChange={(e) => handleChangeData(e, this)}
                                               type="text"
                                               className="form-control" name="name"/>
                                        {errors.name && <p className="small text-danger">{errors.name[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default BookTagAdd;