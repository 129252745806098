import React, {Component} from 'react';

let ctrlIsPressed = false;
const $ = window.$;

class Table extends Component {
    state = {
        selected_rows: [], //stores clicked rows.,
        columns: []
    };

    componentDidMount() {
        ctrlIsPressed = false;
        $(document).keydown(function (e) {
            if (e.ctrlKey) {
                ctrlIsPressed = true;
            }

        });

        $(document).keyup(function () {
            ctrlIsPressed = false;
        });

        const {selectAction, selectActionText, columns} = this.props;
        this.setState({
            columns: columns
        });

        if (selectAction && selectActionText) {
            columns.unshift(
                {
                    dataField: 'mark',
                    text: 'Mark',
                    //  formatter: row => `${row.first_name} ${row.last_name}`
                },
            )
            this.setState({
                columns: columns
            });
        }
    }

    handleSingleRowClick = (row, marking) => {
        if (ctrlIsPressed || marking) {
            const selected_rows = [...this.state.selected_rows];

            //If it already exists in the list, unselect it (delete it)
            let newRows = [];

            if (this.isRowInList(row)) {
                //if its in the list, we remove it.
                for (let i = 0; i < selected_rows.length; i++) {
                    let theRow = selected_rows[i];
                    if (theRow.id !== row.id) {
                        newRows.push(theRow);
                    }
                }
            } else {
                newRows = newRows.concat(selected_rows);
                newRows.push(row);
            }
            this.setState({
                selected_rows: newRows
            })
        }
    }

    isRowInList = (row) => {
        const selected_rows = [...this.state.selected_rows];
        for (let i = 0; i < selected_rows.length; i++) {
            let theRow = selected_rows[i];
            if (theRow.id === row.id) {
                return true;
            }
        }
        return false;
    }

    getPages = () => {
        let {count, noPerPage, specificPageFunc, currentPage} = this.props;
        let page = 1;
        let children = [];
        let pages = [];
        while (count > 0) {
            pages.push(page);
            page++;
            count = count - noPerPage;
        }

        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            children.push(
                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                    <a onClick={e => {
                        e.preventDefault();
                        if (specificPageFunc)
                            specificPageFunc(page);
                    }} className="page-link" href="/#">{page}</a>
                </li>)
        }

        return children;
    }

    render() {
        const {selected_rows, columns} = this.state;
        const {
            nextPageFunc,
            previousPageFunc,
            data, caption,
            actionText, actionFunc,
            hideAction, selectAction,
            selectActionText,
            searchAction,
            loadingSearch,
            selected_filter_index,
            filterColumns, //Columns to filter by when displaying. Calls the filter Function to handle displaying of records.
            filterAction //Invoked when a filter is clicked
        } = this.props;

        return (
            <div>
                {data && (
                    <div>
                        <div style={{bottom: 0, right: 0}} className="position-fixed">
                            {selectAction && typeof selectAction === "function" && selectActionText && selected_rows.length > 0 ?
                                <button onClick={() => selectAction(selected_rows)}
                                        className="btn btn-success btn mr-3 d-flex align-items-center justify-content-center rounded-0">
                                    <span
                                        className="material-icons mr-2">
                                        check
                                    </span>
                                    {selectActionText}
                                </button> : null
                            }

                            {selectAction && Array.isArray(selectAction) && selectActionText && selected_rows.length > 0 ?
                                selectAction.map((func, index) =>
                                    <button style={{margin: 10}} key={index} onClick={() => func(selected_rows)}
                                            className="btn btn-success btn mr-3 d-flex align-items-center justify-content-center rounded-0">
                                        <span
                                            className="material-icons mr-2">
                                            check
                                        </span>
                                        {selectActionText[index]}
                                    </button>
                                )
                                : null
                            }

                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-between my-3">
                            <div className="d-flex flex-column align-items-start justify-content-between my-3">
                                <h3 className="float-left">{caption || "All Records"}</h3>
                                {selectAction && selectActionText ?
                                    <button onClick={() => {
                                        this.setState({
                                            selected_rows: selected_rows.length > 0 ? [] : [...data]
                                        })
                                    }}
                                            className="btn btn-success btn-sm mr-3 mt-3 d-flex align-items-center justify-content-center rounded-0">
                                        {selected_rows.length > 0 ? "Unselect All" : "Select All"}
                                    </button> : null
                                }
                            </div>
                            <div className="float-right">
                                {!hideAction && (
                                    <button onClick={actionFunc || null} className="btn btn-primary btn-sm">
                                        {actionText || "Add Record"}
                                    </button>
                                )}
                            </div>
                        </div>
                        {searchAction && typeof searchAction === "function" ?
                            <div className="d-flex flex-column align-items-stretch justify-content-between my-3">
                                <div className="form-inline my-2 my-lg-0 flex-grow-1">
                                    <div className="input-group mr-sm-2 flex-grow-1 input-main-border">
                                        <input onChange={searchAction} className="form-control border-right-0"
                                               type="search"
                                               placeholder="Search"
                                               aria-label="Search"/>
                                        {loadingSearch ? <div className="input-group-append input-main-border-icon">
                                            <span className="input-group-text bg-transparent" id="basic-addon1">
                                                <div
                                                    className="spinner-border spinner-border-sm text-primary"
                                                    role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                        </div> : null}
                                    </div>
                                    <button onClick={searchAction} className="btn btn-primary my-2 my-sm-0"
                                            type="button">Search
                                    </button>
                                </div>
                                {/* {searchColumns && searchColumns.length > 0 ?
                                    <div className="d-flex flex-row my-3">
                                        {searchColumns.map((column, index) => (
                                            <button key={column.dataField}
                                                    className={`btn ${index === 0 ? "btn-success" : "btn-outline-success"} btn-sm mr-3`}
                                            >{column.text}</button>
                                        ))}
                                    </div> : null
                                }*/}

                            </div> : null
                        }
                        {filterColumns && filterColumns.length > 0 ?
                            <div className="d-flex flex-column align-items-stretch justify-content-between my-3">
                                <div style={{overflow: "auto"}} className="d-flex flex-row my-3 py-3">
                                    {filterColumns.map((column, index) => (
                                        <button key={column.dataField}
                                                onClick={() => {
                                                    if (filterAction) {
                                                        filterAction(column.dataField, index);
                                                        this.setState({
                                                            selected_filter_index: index
                                                        })
                                                    }
                                                }}
                                                className={`btn ${index === selected_filter_index ? "btn-success" : "btn-outline-success"} btn-sm mr-3 text-nowrap`}
                                        >{column.text}</button>
                                    ))}
                                </div>
                            </div> : null
                        }
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                <tr>
                                    {columns.map(column => {
                                        return (
                                            <th key={column.dataField}>{column.text}</th>
                                        )
                                    })}
                                </tr>
                                </thead>
                                <tbody>
                                {data.length === 0
                                    ?
                                    <tr>
                                        <td colSpan={columns.length}>No data found.</td>
                                    </tr>
                                    :
                                    <>
                                        {data.map((row, index) => (
                                            <tr style={this.isRowInList(row) ? {background: "#c6f5f1"} : null}
                                                onClick={() => this.handleSingleRowClick(row)} key={row.id}>
                                                {columns.map(column => {
                                                    if (column.dataField === "mark") return <td key={index}>
                                                        <div className="form-check">
                                                            <input onChange={() => this.handleSingleRowClick(row, true)}
                                                                   className="form-check-input" type="checkbox"
                                                                   checked={this.isRowInList(row)}
                                                                   id="defaultCheck1"/>
                                                        </div>
                                                    </td>
                                                    const field = column.dataField;
                                                    const value = column.formatter
                                                        ? column.formatter(row[field], row) : row[field];
                                                    return <td key={column.dataField}>{value?.length > 30 ?
                                                        `${value.substring(0, 30)}...` : value
                                                    }</td>;
                                                })}
                                            </tr>
                                        ))}
                                    </>
                                }
                                </tbody>
                            </table>
                        </div>
                        <nav aria-label="Page navigation example">
                            {
                                previousPageFunc && nextPageFunc ?
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a onClick={e => {
                                                e.preventDefault();
                                                nextPageFunc();
                                            }} className="page-link" href="/#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        {
                                            this.getPages()
                                        }
                                        <li className="page-item">
                                            <a onClick={e => {
                                                e.preventDefault();
                                                previousPageFunc();
                                            }} className="page-link" href="/#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul> : null
                            }
                        </nav>
                    </div>
                )}
            </div>
        );
    }
}

export default Table;