import urls from "./urls";

export const routes = [
    urls.index,
    urls.login,
    urls.logout,
    urls.settings,
    urls.books,
    urls.book_details,
    urls.orders,
    urls.order_details,
    urls.transactions,
    urls.book_tags,
    urls.book_tags_details,
    urls.blogs,
    urls.blog_add,
    urls.blog_details,
    urls.blog_edit
];