import React, {Component} from 'react';
import {getOrders} from "../../redux/orders/actions";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {orderColumns} from "../../providers/orders/data";

const mapStateToProps = state => (state.ordersState);

const mapDispatchToProps = dispatch => ({
    getOrders: () => dispatch(getOrders()),
});


class OrdersList extends Component {

    componentDidMount() {
        if (this.props.orders == null)
            this.props.getOrders();
    }

    render() {
        let {loading, orders} = this.props;
        if (orders == null) orders = [];

        return (
            loading ?
                <div className="text-center">
                    <CircularProgress/>
                </div> :
                <div className="card my-5">
                    <div className="card-body">
                        <Table
                            caption={"Recently Added Orders"}
                            hideAction={true}
                            columns={orderColumns}
                            data={orders}
                        />
                    </div>
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);