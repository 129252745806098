import React, {Component} from 'react';
import {onMount} from "../../providers/loader";
import endpoints from "../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {blogsColumns} from "../../providers/blogs/data";

class Blogs extends Component {
    state = {
        blogs: []
    }

    componentDidMount() {
        this.getBlogs();
    }

    getBlogs = () => {
        onMount(this, endpoints.all_blogs, "blogs");
    }

    handleAddBlog = e => {
        e.preventDefault();
        const history = this.props.history;
        history.push(`/blogs/add`)
    }

    render() {
        let {loading, blogs} = this.state;
        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card my-5">
                    <div className="card-body">
                        <Table
                            caption={"All Blogs"}
                            hideAction={false}
                            actionText={"Add Blog"}
                            actionFunc={this.handleAddBlog}
                            columns={blogsColumns}
                            data={blogs}
                        />
                    </div>
                </div>
        );
    }
}

export default Blogs;