import notFound from "../../assets/img/404.png";
import {Link} from "react-router-dom";
import React from "react";

export const blogsColumns = [
    {
        dataField: 'cover_image',
        text: 'Cover',
        formatter: path => (
            <img style={{
                height: "10vh",
                objectFit: "contain"
            }}
                 className="mr-3"
                 src={path || notFound}
                 alt={"book placeholder"}/>
        )
    },
    {
        dataField: 'title',
        text: 'Title',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'description',
        text: 'Description',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'slug',
        text: 'Action',
        formatter: id => <Link to={`/blogs/${id}`} className="btn btn-sm btn-success">View</Link>
    },
]