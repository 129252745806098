import React, {Component} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import Swal from "sweetalert2";
import CircularProgress from "@material-ui/core/CircularProgress";
import notFound from "../../assets/img/404.png";

const parse = require('html-react-parser');

class BlogDetails extends Component {

    state = {}

    componentDidMount() {
        this.getBlog();
    }

    getBlog = () => {
        const {id} = this.props.match.params;
        this.setState({
            loading: true
        });
        makeRequest(
            GET_REQUEST,
            `${endpoints.all_blogs}${id}`,
            {},
            response => {
                this.setState({
                    blog: response.data,
                })
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                this.setState({
                    loading: false
                })
            });
    }

    handleDelete = () => {
        const {id} = this.props.match.params;
        const {history} = this.props;
        Swal.fire({
                title: 'Information!',
                text: 'Are you sure you want delete this?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            }
        ).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.all_blogs_admin}${id}`, {}, () => {
                    Swal.fire(
                        'Success!',
                        'Blog deleted successfully!',
                        'success',
                    ).then(() => history.push('/blogs'));
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    this.setState({
                        loadingDelete: false
                    })
                })
            }

        });
    }

    render() {
        const {loading, blog, loadingDelete} = this.state;
        const {history} = this.props;
        return (
            <div className="my-5">
                {
                    loading ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        blog ?
                            <div className="col-12 card px-5 shadow-sm py-5">
                                <div className="d-flex justify-content-between mb-5">
                                    <h1 className="m-0"> {blog.title}</h1>
                                    <div>
                                        <button onClick={()=>{
                                            history.push(`/blogs/add/${blog.slug}`)
                                        }}
                                                className="btn btn-sm btn-info ml-3">Edit
                                        </button>
                                        <button
                                            disabled={loadingDelete}
                                            onClick={this.handleDelete}
                                            className="btn btn-sm btn-danger ml-3">{loadingDelete ? "Loading.." : "Delete"}
                                        </button>
                                    </div>
                                </div>
                                <div style={{height: "60vh"}}>
                                    <img style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain"
                                    }}
                                         src={blog.cover_image || notFound}
                                         alt={"blog placeholder"}/>
                                </div>
                                <h4 className="my-5"> {blog.description}</h4>
                                <div style={{overflow: "hidden"}} className="w-100">
                                    {parse(blog?.body || "")}
                                </div>
                            </div> :
                            <div className="text-center">
                                <p>Blog could not be loaded.</p>
                            </div>
                }
            </div>
        );
    }
}

export default BlogDetails;