import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";

export const FETCH_ORDERS = "fetch_vehicles";
export const LOADING_ORDERS = "loading_vehicles";

export const getOrders = () => {
    return dispatch => {
        dispatch({
            type: LOADING_ORDERS
        })
        makeRequest(GET_REQUEST, `${endpoints.orders}`, {}, response => {
            const res = response.data;
            if (res.data) {
                let myObj = {
                    orders: res.data,
                    first: res.links.first,
                    last: res.links.last,
                    next: res.links.next,
                    prev: res.links.prev,
                    type: FETCH_ORDERS
                };
                return dispatch(myObj)
            }
            return dispatch({
                orders: [],
                type: FETCH_ORDERS
            })

        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}

/*
export const getSoldVehicles = () => {
    return dispatch => {
        dispatch({
            type: LOADING_ORDERS
        })
        makeRequest(GET_REQUEST, `${endpoints.vehicles}?bought=true`, {}, response => {
            const suppliers = response.data;
            return dispatch({
                sold_vehicles: suppliers,
                type: FETCH_ORDERS
            })
        }, error => {
            showAlert('error', "Error!", parseErrorResponse(error));
            console.log(error);
        });
    }
}*/
