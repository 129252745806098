import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import notFound from "../../assets/img/404.png";
import {numberWithCommas, parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import {makeRequest} from "../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import BookAdd from "./add";
import Swal from "sweetalert2";

class BookDetails extends Component {

    state = {}

    componentDidMount() {
        this.getBook();
    }

    getBook = () => {
        const {id} = this.props.match.params;
        this.setState({
            loading: true
        });
        makeRequest(
            GET_REQUEST,
            `${endpoints.all_books}${id}`,
            {},
            response => {
                this.setState({
                    book: response.data,
                })
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                this.setState({
                    loading: false
                })
            });
    }

    handleDelete = () => {
        const {id} = this.props.match.params;
        const {history} = this.props;
        Swal.fire({
                title: 'Information!',
                text: 'Are you sure you want delete this?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            }
        ).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.all_books}${id}`, {}, () => {
                    Swal.fire(
                        'Success!',
                        'Book deleted successfully!',
                        'success',
                    ).then(() => history.push('/books'));
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    this.setState({
                        loadingDelete: false
                    })
                })
            }

        });
    }

    render() {
        const {loading, book, loadingDelete} = this.state;
        return (
            <div className="my-5">
                {
                    loading ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        book ?
                            <div className="col-12 card px-5 shadow-sm">
                                <BookAdd book={book} getBooks={() => this.getBook()}/>
                                <div className="row align-items-center">
                                    <div className="col-md-5 px-5">
                                        <div style={{height: "60vh"}}>
                                            <img style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain"
                                            }}
                                                 src={book.path || notFound}
                                                 alt={"book placeholder"}/>
                                        </div>
                                    </div>
                                    <div className="col-md-7 px-5">
                                        <div className="d-flex align-items-center mb-3">
                                            <h4 className="m-0"> {book.title}</h4>
                                            <button data-toggle="modal" data-target="#addBookModal"
                                                    className="btn btn-sm btn-info ml-3">Edit
                                            </button>
                                            <button
                                                disabled={loadingDelete}
                                                onClick={this.handleDelete}
                                                className="btn btn-sm btn-danger ml-3">{loadingDelete ? "Loading.." : "Delete"}
                                            </button>
                                        </div>
                                        <p className="mb-3"> {book.description}</p>

                                        <p className="font-weight-bold mb-3">
                                            KES {numberWithCommas(book.price)}
                                        </p>
                                        <p>
                                            {
                                                book.tags.map(tag => <span key={tag.id}
                                                    className="badge badge-secondary ml-1">{tag.name}</span>)
                                            }
                                        </p>

                                    </div>
                                </div>
                            </div> :
                            <div className="text-center">
                                <p>Book could not be loaded.</p>
                            </div>
                }
            </div>
        );
    }
}

export default BookDetails;