import React, {Component} from 'react';
import {makeRequest} from "../../helpers/network_utils";
import {DELETE_REQUEST, GET_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../helpers/helper_functions";
import Swal from "sweetalert2";
import CircularProgress from "@material-ui/core/CircularProgress";
import BookAdd from "../book_tags/add";

class BookTagDetails extends Component {
    state = {}

    componentDidMount() {
        this.getBookTag();
    }

    getBookTag = () => {
        const {id} = this.props.match.params;
        this.setState({
            loading: true
        });
        makeRequest(
            GET_REQUEST,
            `${endpoints.book_tags}${id}`,
            {},
            response => {
                this.setState({
                    book_tag: response.data,
                })
            },
            error => {
                showAlert('error', 'Error', parseErrorResponse(error))
            }, () => {
                this.setState({
                    loading: false
                })
            });
    }

    handleDelete = () => {
        const {id} = this.props.match.params;
        const {history} = this.props;
        Swal.fire({
                title: 'Information!',
                text: 'Are you sure you want delete this?',
                icon: 'info',
                showCancelButton: true,
                cancelButtonText: 'Cancel'
            }
        ).then(result => {
            if (result.isConfirmed) {
                this.setState({
                    loadingDelete: true
                })
                makeRequest(DELETE_REQUEST, `${endpoints.book_tags}${id}`, {}, () => {
                    Swal.fire(
                        'Success!',
                        'Book Tag deleted successfully!',
                        'success',
                    ).then(() => history.push('/book-tags'));
                }, error => {
                    showAlert('error', 'Error', parseErrorResponse(error))
                }, () => {
                    this.setState({
                        loadingDelete: false
                    })
                })
            }

        });
    }

    render() {
        const {loading, book_tag, loadingDelete} = this.state;
        return (
            <div className="my-5">
                {
                    loading ?
                        <div className="text-center">
                            <CircularProgress/>
                        </div> :
                        book_tag ?
                            <div className="col-12 card px-5 py-5 shadow-sm">
                                <BookAdd book_tag={book_tag} getBookTags={() => this.getBookTag()}/>
                                <div className="row align-items-center">
                                    <div className="col-md-7 px-5">
                                        <div
                                            className="d-flex align-items-center flex-column flex-md-row justify-content-between mb-3">
                                            <h3 className="m-0 mb-2 text-center"> {book_tag.name}</h3>
                                            <button data-toggle="modal" data-target="#addBookModal"
                                                    className="btn btn-info ml-3 mb-2">Edit
                                            </button>
                                            <button
                                                disabled={loadingDelete}
                                                onClick={this.handleDelete}
                                                className="btn btn-danger ml-3 mb-2">{loadingDelete ? "Loading.." : "Delete"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="text-center">
                                <p>Book tag could not be loaded.</p>
                            </div>
                }
            </div>
        );
    }
}

export default BookTagDetails;