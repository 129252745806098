import React, {Component} from 'react';
import {onMount} from "../../providers/loader";
import endpoints from "../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {transactionColumns} from "../../providers/transactions/data";

class Transactions extends Component {

    state = {
        transactions: []
    }

    componentDidMount() {
        this.getTransactions();
    }

    getTransactions = () => {
        onMount(this, endpoints.transactions, "transactions");
    }

    render() {
        let {loading, transactions} = this.state;
        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card my-5">
                    <div className="card-body">
                        <Table
                            caption={"All Transactions"}
                            hideAction={true}
                            columns={transactionColumns}
                            data={transactions}
                        />
                    </div>
                </div>
        );
    }
}

export default Transactions;