import {Link} from "react-router-dom";
import React from "react";
import {numberWithCommas} from "../../helpers/helper_functions";

export const orderColumns = [
    {
        dataField: 'reference_code',
        text: 'Reference Code',
        formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'transaction_code',
        text: 'Transaction Code',
        formatter: (model, row) => `${row?.model?.make?.name || "-"}`
    },
    {
        dataField: 'status',
        text: 'Status',
        formatter: status => <div className="d-flex justify-content-between">
            <p
                className={`${status === "pending" ? "badge-info" : "badge-success"}
                             px-3 py-0 rounded-pill small d-flex align-items-center`}>
                {status?.toUpperCase() || "-"}
            </p>
        </div>
    },
    {
        dataField: 'total',
        text: 'Total',
        formatter: amount => `KES ${numberWithCommas(amount)}`
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: id => <Link to={`/orders/${id}`} className="btn btn-sm btn-success">View</Link>
    },
]