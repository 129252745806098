import {numberWithCommas} from "../../helpers/helper_functions";
import React from "react";
import notFound from "../../assets/img/404.png";
import {Link} from "react-router-dom";

export const booksColumns = [
    {
        dataField: 'path',
        text: 'Cover',
        formatter: path => (
            <img style={{
                height: "10vh",
                objectFit: "contain"
            }}
                 className="mr-3"
                 src={path || notFound}
                 alt={"book placeholder"}/>
        )
    },
    {
        dataField: 'title',
        text: 'Title',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'description',
        text: 'Description',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'price',
        text: 'Price',
        formatter: price => `KES ${numberWithCommas(price || "-")}`
    },
    {
        dataField: 'slug',
        text: 'Action',
        formatter: id => <Link to={`/books/${id}`} className="btn btn-sm btn-success">View</Link>
    },
];