import React from "react";
import {Link} from "react-router-dom";

export const bookTagsColumns = [
    {
        dataField: 'name',
        text: 'Name',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'id',
        text: 'Action',
        formatter: id => <Link to={`/book-tags/${id}`} className="btn btn-sm btn-success">View</Link>
    },
];