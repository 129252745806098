import React, {Component} from 'react';
import Swal from "sweetalert2";
import {makeRequest} from "../../helpers/network_utils";
import {GET_REQUEST, POST_REQUEST} from "../../values/globals";
import endpoints from "../../routes/endpoints";
import {handleChangeData, parseErrorResponse, showAlert} from "../../helpers/helper_functions";

const $ = window.$;

class BookAdd extends Component {
    state = {
        data: {},
        name: '',
        loading: false,
        errors: {},
        book_types: [],
        book_tags: []
    }

    componentDidMount() {
        this.getBookTypes();
        this.getBookDetails();
        this.getBookTags();
    }

    getBookTypes = () => {
        makeRequest(GET_REQUEST, endpoints.book_types, {}, response => {
            this.setState({
                book_types: response.data
            })
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        })
    }

    getBookTags = () => {
        makeRequest(GET_REQUEST, endpoints.book_tags, {}, response => {
            this.setState({
                book_tags: response.data
            })
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        })
    }

    getBookDetails = () => {
        const book = this.props?.book;
        if (book) {
            book["tags"] = book.tags.map(tag => tag.id);
            this.setState({
                data: book
            })
        }
    }

    tagExists = (tag) => {
        const {data} = this.state;
        let tags = data?.tags || [];
        for (let i = 0; i < tags.length; i++) {
            if (tags[i] === tag.id) return true;
        }
        return false;
    }

    handleTagChange = (e, tag) => {
        const {data} = this.state;
        let newValue = data?.tags || [];

        if (e.target.checked)
            newValue.push(tag);
        else
            newValue = this.deleteTag(tag)
        let newE = {
            target: {
                value: newValue,
                name: "tags"
            },
        }
        handleChangeData(newE, this);
    }

    deleteTag = tag => {
        const {data} = this.state;
        let newValue = data?.tags || [];
        let newFeature = [];
        for (let i = 0; i < newValue.length; i++) {
            if (newValue[i] !== tag)
                newFeature.push(newValue[i]);
        }
        return newFeature;
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        const {data} = this.state;
        let formData = new FormData();
        let keys = Object.keys(data);

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = data[key];
            if (key === "type") {
                if (typeof data[key] === 'object')
                    value = data[key]?.id;
            }
            formData.append(key, value);
        }
        //  formData.append("id", data["type"]?.id);

        const book = this.props?.book;
        const method = POST_REQUEST;
        if (book)
            formData.append('_method', 'PATCH');
        const route = book?.id ? `${endpoints.all_books}${book?.slug}` : endpoints.all_books;
        makeRequest(method, route, formData, () => {
            Swal.fire(
                'Success!',
                'Book was saved successfully!',
                'success'
            ).then(() => {
                this.props.getBooks();
                $("#addBookModal").modal('toggle');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            });
        }, (error) => {
            if (error.response.status === 413) {
                showAlert('error', 'Error', 'The uploaded file is larger than 1GB. Please upload files less than 1GB.')
            } else
                this.setState({
                    errors: error.response.data
                })
        }, () => {
            this.setState({loading: false})
        })
    }

    render() {
        const {errors, loading, book_types, data, book_tags} = this.state;
        return (
            <div className="modal fade" id="addBookModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Add Book</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <form>
                                    <div className="form-group">
                                        <label>Book Type</label>
                                        <select name="type" className="form-control"
                                                onChange={e => handleChangeData(e, this)}>
                                            {
                                                data.type ?
                                                    <option value={data.type.id}>{data.type.name}</option> :
                                                    <option value="">Select Book Type</option>
                                            }
                                            {
                                                book_types.map(type => data?.type?.id !== type.id &&
                                                    <option key={type.id}
                                                            value={type.id}>{type.name}</option>)
                                            }
                                        </select>
                                        {errors.type && <p className="small text-danger">{errors.type[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label>Title</label>
                                        <input value={data.title || ""} onChange={(e) => handleChangeData(e, this)}
                                               type="text"
                                               className="form-control" name="title"/>
                                        {errors.title && <p className="small text-danger">{errors.title[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label>Cover Image:</label>
                                        <input
                                            onChange={event => {
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        cover_image: event.target.files[0]
                                                    }
                                                })
                                            }}
                                            type="file"
                                            accept="image/*"
                                            className="form-control"
                                            required/>
                                        {errors.cover_image && (
                                            <p className="mb-0 small text-danger">{errors.cover_image[0]}</p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input
                                            value={data.description || ""}
                                            onChange={(e) => handleChangeData(e, this)} type="text"
                                            className="form-control" name="description"/>
                                        {errors.description &&
                                        <p className="small text-danger">{errors.description[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label>File:</label>
                                        <input
                                            onChange={event => this.setState({
                                                data: {
                                                    ...this.state.data,
                                                    file: event.target.files[0]
                                                }
                                            })}
                                            type="file"
                                            className="form-control"
                                            required/>
                                        {errors.file && (
                                            <p className="mb-0 small text-danger">{errors.file[0]}</p>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Price</label>
                                        <input
                                            value={data.price || ""}
                                            onChange={(e) => handleChangeData(e, this)} type="number"
                                            className="form-control" name="price"/>
                                        {errors.price && <p className="small text-danger">{errors.price[0]}</p>}
                                    </div>
                                    <div className="form-group">
                                        <label>Tags</label>
                                        <div className="row mx-auto">
                                            {book_tags ?
                                                book_tags.map((tag) =>
                                                    <div key={tag.id}
                                                         className="form-check col-xl-4 col-md-6">
                                                        <input onChange={e => this.handleTagChange(e, tag.id)}
                                                               checked={this.tagExists(tag)}
                                                               className="form-check-input" type="checkbox" value=""/>
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            {tag.name}
                                                        </label>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        {errors.tags &&
                                        <p className="small text-danger">{errors.tags[0]}</p>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={this.handleSubmit} type="button" className="btn btn-primary"
                                    disabled={loading}>{loading ? "Loading..." : "Save"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default BookAdd;