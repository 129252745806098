import React, {Component} from 'react';
import {logout} from "../../../helpers/login";
import {makeRequest} from "../../../helpers/network_utils";
import {GET_REQUEST} from "../../../values/globals";
import endpoints from "../../../routes/endpoints";
import {parseErrorResponse, showAlert} from "../../../helpers/helper_functions";
import CircularProgress from "@material-ui/core/CircularProgress";

class Logout extends Component {

    componentDidMount() {
        makeRequest(GET_REQUEST, endpoints.logout, {}, () => {
            //window.location = "/";
            logout();
        }, error => {
            showAlert('error', 'Error', parseErrorResponse(error))
        })
    }

    render() {
        return (
            <div className="text-center my-5 py-5">
                <CircularProgress/>
                <p> Logging out.</p>
            </div>
        );
    }
}

export default Logout;