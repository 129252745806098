import React from "react";
import {Link} from "react-router-dom";
import {numberWithCommas} from "../../../helpers/helper_functions";

function OrderItem(props) {
    const {item} = props;
    return (
        <div className="card shadow-sm my-3">
            <div className="card-body">
                <div className="col-12">
                    <h3>{item.book.title || "-"}</h3>
                    <div className="d-flex justify-content-between">
                        <p> KES {numberWithCommas(item.book.price)}</p>
                        <p> {item.quantity} {item.quantity > 1 ? "items" : 'item'}</p>
                    </div>
                    <hr className="mb-3"/>
                    <div className="d-flex justify-content-between">
                        <h4>
                            Total: KES {numberWithCommas(item.book.price * item.quantity)}
                        </h4>
                        <Link className="btn btn-success" to={`/books/${item.book.slug}`}>
                            View
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderItem;