import React from "react";
import {Link} from "react-router-dom";

function SideBar() {
    /* const user = getUserObject();*/
    return (
        <div className="left-nav-wrap">
            <div className="left-sidebar">
                <nav className="sidebar-menu">
                    <ul id="nav-accordion">
                        <li>
                            <Link to="/">
                                <i className="ti-home"/>
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/books">
                                <i className="icon-grid"/>
                                <span>Books</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/orders">
                                <i className="icon-basket"/>
                                <span>Orders</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/transactions">
                                <i className="icon-layers"/>
                                <span>Transactions</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/book-tags">
                                <i className="icon-tag"/>
                                <span>Book Tags</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/blogs">
                                <i className="icon-notebook"/>
                                <span>Blogs</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/settings/profile">
                                <i className="icon-settings"/>
                                <span>Settings</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/expenses">
                                <i className="icon-wallet"/>
                                <span>Expenses</span>
                            </Link>
                        </li>

                         <li>
                            <Link to="/vehicles">
                                <i className="icon-info"/>
                                <span>Inquiries</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/clients">
                                <i className="icon-people"/>
                                <span>Clients</span>
                            </Link>
                        </li>


                        {
                            user?.role === "admin" &&
                            <li>
                                <Link to="/admin/settings">
                                    <i className="ti-user"/>
                                    <span>System Users</span>
                                </Link>
                            </li>
                        }*/}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default SideBar;