import React from "react";

export const transactionColumns = [
    {
        dataField: 'order_reference',
        text: 'Order Reference',
        //formatter: numberPlate => `${numberPlate || "-"}`
    },
    {
        dataField: 'user',
        text: 'User',
        formatter: user =>
            <div className="d-flex flex-column">
                <p><b>First Name: </b><br/>{user.first_name}</p>
                <p><b>Last Name: </b><br/>{user.last_name}</p>
                <p><b>Email: </b><br/>{user.email}</p>
                <p><b>Phone Number: </b><br/>{user.phone_number}</p>
            </div>
    },
    {
        dataField: 'response',
        text: 'Transaction Details',
        formatter: transaction => {
            const keys = Object.keys(transaction || {});
            return (
                <div className="d-flex flex-column">
                    {
                        keys.map(key =>
                            <p key={key}><b>{key}: <br/></b>{transaction[key]}</p>
                        )
                    }
                </div>
            );
        }

    },
    {
        dataField: 'created_at',
        text: 'Date',
        formatter: created => `${new Date(created).toLocaleString()}`
    },
];