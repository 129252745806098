import React, {Component} from 'react';
import {onMount} from "../../providers/loader_plain";
import endpoints from "../../routes/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import BookAdd from "./add";
import Table from "../../components/Table";
import {bookTagsColumns} from "../../providers/book_tags/data";

const $ = window.$;

class BookTags extends Component {
    state = {
        book_tags: []
    }

    componentDidMount() {
        this.getBookTags();
    }

    getBookTags = () => {
        onMount(this, endpoints.book_tags, "book_tags");
    }

    handleAddBookTag = e => {
        e.preventDefault();
        $('#addBookModal').modal('show');
    }

    render() {
        let {loading, book_tags} = this.state;
        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card my-5">
                    <div className="card-body">
                        <BookAdd getBookTags={this.getBookTags}/>
                        <Table
                            caption={"All Book Tags"}
                            hideAction={false}
                            actionText={"Add Book Tag"}
                            actionFunc={this.handleAddBookTag}
                            columns={bookTagsColumns}
                            data={book_tags}
                        />
                    </div>
                </div>
        );
    }
}

export default BookTags;