import React, {Component} from "react";
import Totals from "./totals";
import Vehicles from "./orders";

class Dashboard extends Component {
    render() {
        return (
            <div>
                <Totals/>
               {/* <ExpensesStats/>*/}
                <Vehicles/>
            </div>
        );
    }
}

export default Dashboard;