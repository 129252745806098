import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../../components/Table";
import {onMount} from "../../providers/loader";
import endpoints from "../../routes/endpoints";
import {booksColumns} from "../../providers/books/data";
import BookAdd from "./add";

const $ = window.$;

class BooksList extends Component {
    state = {
        books: []
    }

    componentDidMount() {
        this.getBooks();
    }

    getBooks = () => {
        onMount(this, endpoints.all_books);
    }

    handleAddBook = e => {
        e.preventDefault();
        $('#addBookModal').modal('show');
    }

    render() {
        let {loading, books} = this.state;

        return (
            loading ?
                <div className="text-center my-5 py-5">
                    <CircularProgress/>
                </div> :
                <div className="card my-5">
                    <div className="card-body">
                        <BookAdd getBooks={this.getBooks}/>
                        <Table
                            caption={"All Books"}
                            hideAction={false}
                            actionText={"Add Book"}
                            actionFunc={this.handleAddBook}
                            columns={booksColumns}
                            data={books}
                        />
                    </div>
                </div>
        );
    }
}

export default BooksList;